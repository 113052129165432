(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name components.controller:ActionButtonCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.components')
  .controller('ActionButtonCtrl', ActionButtonCtrl);

  function ActionButtonCtrl(actionButton) {
    var vm = this;
    vm.ctrlName = 'ActionButtonCtrl';
    vm.buttons = actionButton;
  }
}());
